import Swiper, { Pagination, Scrollbar } from 'swiper';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/swiper.scss';

Swiper.use([Pagination, Scrollbar]);

window.addEventListener(
    "DOMContentLoaded",
    () => {
        const swiper = new Swiper('.swiper-container', {
            modules: [Pagination, Scrollbar],
            direction: 'horizontal',
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },
        });
    },
    false
);
