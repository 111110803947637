window.EasySetTabTab1Data = class EasySetTabTab1Data {
    constructor({ area = '', height = '', width = '',}) {
        this.area = parseInt(area)     // f1 area: 平米数を入力
        this.height = parseInt(height) // f2 height: 高さを入力
        this.width = parseInt(width)   // f3 width: 幅を選択
        // f4 total_span: 総スパン数
        this.total_span = EasySetTabTabFunctions.round(this.area / 3.24,0);
        console.log(this.total_span);
        // f5 step 段数
        this.step = EasySetTabTabFunctions.round(this.height / 1.8,0)+1;
        console.log(this.step);
        // f6 span スパン数
        this.span = EasySetTabTabFunctions.round(this.total_span / this.step, 0);
        console.log(this.span);
        // f7 bottom_length 底辺長さ
        this.bottom_length = this.span * 1.829;
        console.log(this.bottom_length);
        // f8 stair_num 階段個所数
        this.stair_num = EasySetTabTabFunctions.roundDown((this.bottom_length / 40) + 1,0);
        console.log(this.stair_num);
        // f9 prop_num 3.6支柱数
        this.prop_num = ((parseInt(this.height / 3.6)) * (this.span + 1)) * 2;
        console.log(this.prop_num);

        this.logics = {}
        if (width == 600) {
            this.logics.支柱_27支柱A = EasySetTabTabFunctions.round((this.prop_num * 0.2),0);
            this.logics.支柱_36支柱A = (parseInt(this.height / 3.6) * (this.span + 1)) * 2 - this.logics.支柱_27支柱A;
            this.logics.支柱_18支柱A = this.prop_num * 0.5;
            this.logics.支柱_09支柱A = EasySetTabTabFunctions.round((this.prop_num * 0.6),0);
            this.logics.ブラケット_40ブラケット = this.logics.支柱_36支柱A;
            this.logics.ブラケット_25ブラケット = this.logics.支柱_27支柱A;
            this.logics.ブラケット_40張出ブラケット = null;
            this.logics.手摺_1800手摺 = ((this.step - 1) * 4 + 2) * (this.span + 1);
            this.logics.手摺_1200手摺 = EasySetTabTabFunctions.round((this.logics.手摺_1800手摺 * 0.19),0);
            this.logics.手摺_900手摺 = EasySetTabTabFunctions.round((this.logics.手摺_1800手摺 * 0.18),0);
            this.logics.手摺_600手摺 = EasySetTabTabFunctions.round((this.step * 3 * this.span),0) + this.logics.手摺_900手摺;
            this.logics['踏板_4018踏板A'] = (this.step - 1) * (this.span + 1);
            this.logics['踏板_4012踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_4018踏板A'] * 0.15),0);
            this.logics['踏板_4009踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_4018踏板A'] * 0.2),0);
            this.logics['踏板_4006踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_4018踏板A'] * 0.1),0);
            // fix end
            this.logics['踏板_2518踏板A'] = null;
            this.logics['踏板_2512踏板A'] = null;
            this.logics['踏板_2509踏板A'] = null;
            this.logics['踏板_2506踏板A'] = null;
            this.logics['階段・扉_昇降階段（アルミ製）'] = this.stair_num * (this.step - 1);
            this.logics['ジャッキ_固定ジャッキ'] = (this.span + 1) * 2;
            this.logics['アンダーベース_アンダーベース（プラ）'] = this.logics.ジャッキ_自在ジャッキ;
        } else if (width == 900) {
            this.logics.支柱_27支柱A = EasySetTabTabFunctions.round((this.prop_num * 0.2),0);
            this.logics.支柱_36支柱A = (parseInt(this.height / 3.6) * (this.span + 1)) * 2 - this.logics.支柱_27支柱A;
            this.logics.支柱_18支柱A = this.prop_num * 0.5;
            this.logics.支柱_09支柱A = EasySetTabTabFunctions.round((this.prop_num * 0.6),0);
            this.logics.ブラケット_40ブラケット = this.logics.支柱_36支柱A;
            this.logics.ブラケット_25ブラケット = this.logics.支柱_27支柱A;
            this.logics.ブラケット_40張出ブラケット = null;
            this.logics.手摺_1800手摺 = ((this.step - 1) * 4 + 2) * (this.span + 1);
            this.logics.手摺_1200手摺 = EasySetTabTabFunctions.round((this.logics.手摺_1800手摺 * 0.19),0);
            this.logics.手摺_600手摺 = EasySetTabTabFunctions.round((this.logics.手摺_1800手摺 * 0.15),0);
            this.logics.手摺_900手摺 = EasySetTabTabFunctions.round((this.step * 3 * this.span),0) + this.logics.手摺_600手摺;
            this.logics['踏板_4018踏板A'] = (this.step - 1) * (this.span + 1);
            this.logics['踏板_4012踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_4018踏板A'] * 0.15),0);
            this.logics['踏板_4009踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_4018踏板A'] * 0.2),0);
            this.logics['踏板_4006踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_4018踏板A'] * 0.1),0);
            // fix end
            this.logics['踏板_2518踏板A'] = this.logics['踏板_4018踏板A'];
            this.logics['踏板_2512踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_2518踏板A'] * 0.15),0);
            this.logics['踏板_2509踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_2518踏板A'] * 0.2),0);
            this.logics['踏板_2506踏板A'] = EasySetTabTabFunctions.round((this.logics['踏板_2518踏板A'] * 0.1),0);
            this.logics['階段・扉_昇降階段（アルミ製）'] = this.stair_num * (this.step - 1);
            this.logics['ジャッキ_固定ジャッキ'] = (this.span + 1) * 2;
            this.logics['アンダーベース_アンダーベース（プラ）'] = 40;
        }
    }
}
