const jpostalquery = require('jquery');
require('jquery-jpostal-ja');
jpostalquery(function(){
    jpostalquery('#profile_postcode').jpostal({
        postcode: [ '#profile_postcode' ],
        address: {
            '#profile_prefecture_id': '%3',
            '#profile_address_city': '%4%5',
        }
    });
    jpostalquery('#address_postcode').jpostal({
        postcode: [ '#address_postcode' ],
        address: {
            '#address_prefecture_id': '%3',
            '#address_city': '%4%5',
        }
    });
    jpostalquery('#delivery_company_postcode').jpostal({
        postcode: [ '#delivery_company_postcode' ],
        address: {
            '#delivery_company_prefecture_id': '%3',
            '#delivery_company_city': '%4%5',
        }
    });
    jpostalquery('#business_application_business_application_self_employment_attributes_post_code').jpostal({
        postcode: [ '#business_application_business_application_self_employment_attributes_post_code' ],
        address: {
            '#business_application_business_application_self_employment_attributes_prefecture_id': '%3',
            '#business_application_business_application_self_employment_attributes_city': '%4%5',
            '#business_application_business_application_self_employment_attributes_city_kana': '%ASKV9%ASKV10',
        }
    });
    jpostalquery('#business_application_business_application_self_employment_attributes_admin_post_code').jpostal({
        postcode: [ '#business_application_business_application_self_employment_attributes_admin_post_code' ],
        address: {
            '#business_application_business_application_self_employment_attributes_admin_prefecture_id': '%3',
            '#business_application_business_application_self_employment_attributes_admin_city': '%4%5',
            '#business_application_business_application_self_employment_attributes_admin_city_kana': '%ASKV9%ASKV10',
        }
    });
    jpostalquery('#business_application_business_application_corporation_attributes_post_code').jpostal({
        postcode: [ '#business_application_business_application_corporation_attributes_post_code' ],
        address: {
            '#business_application_business_application_corporation_attributes_prefecture_id': '%3',
            '#business_application_business_application_corporation_attributes_city': '%4%5',
            '#business_application_business_application_corporation_attributes_city_kana': '%ASKV9%ASKV10',
        }
    });
    jpostalquery('#business_application_business_application_corporation_attributes_representative_post_code').jpostal({
        postcode: [ '#business_application_business_application_corporation_attributes_representative_post_code' ],
        address: {
            '#business_application_business_application_corporation_attributes_representative_prefecture_id': '%3',
            '#business_application_business_application_corporation_attributes_representative_city': '%4%5',
            '#business_application_business_application_corporation_attributes_representative_city_kana': '%ASKV9%ASKV10',
        }
    })
});