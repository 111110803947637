window.EasySetTabTab3Data = {
    h3000: {
        board: function (v) { return v / 0.5 }, // 安全鋼板：H=3m
        hookbolt: function (v) {return EasySetTabTab3Data.h3000.board(v) * 6 }, // フックボルト
        pipe40: function (v) { return EasySetTabTabFunctions.roundUp(v / 4 * 3, 0) }, // 単管パイプ4.0m（布地：ピン無し）
        pipe30: function (v) { return EasySetTabTabFunctions.roundUp(v / 1.8 + 1, 0) }, // 単管パイプ3.0m（建地：ピン無し）
        pipe25: function (v) { return EasySetTabTab3Data.h3000.pipe30(v) }, // 単管パイプ2.5m（控え：ピン無し）
        pipe20: function (v) { return EasySetTabTabFunctions.round(v/20 + 1,0) }, // 単管パイプ2.0m
        pipe15_korobashi: function (v) { return EasySetTabTab3Data.h3000.pipe30(v) }, // 単管パイプ1.5m（コロバシ：ピン無し）
        pipe15_uchikomi:  function (v) { return EasySetTabTabFunctions.roundUp(EasySetTabTab3Data.h3000.pipe30(v) * 2, 0) }, // 単管パイプ1.5m（打込み：ピン無し）
        orthogonal_clamp: function (v) { return EasySetTabTabFunctions.roundUp(EasySetTabTab3Data.h3000.pipe30(v) * 11 / 2, 0) }, // 直交クランプ
        free_clamp: function (v) { return EasySetTabTabFunctions.roundUp(EasySetTabTab3Data.h3000.pipe30(v) * 9 / 2, 0) }, // 自在クランプ
        joint: function (v) { return EasySetTabTab3Data.h3000.pipe40(v) + 3 } // ジョイント
    },
    h2000: {
        board: function (v) { return v / 0.5 }, // 安全鋼板：H=2m
        hookbolt: function (v) { return EasySetTabTab3Data.h3000.board(v) * 4 }, // フックボルト
        pipe40: function (v) { return v / 4 * 2 }, // 単管パイプ4.0m（布地：ピン無し）
        pipe20_tateji: function (v) { return EasySetTabTabFunctions.roundUp(v / 1.8 * 1 + 1, 0) }, // 単管パイプ2.0m（建地：ピン無し）
        pipe20_hikae: function (v) { return EasySetTabTab3Data.h2000.pipe20_tateji(v) }, // 単管パイプ2.0m（控え：ピン無し）
        pipe10_korobashi: function (v) { return EasySetTabTab3Data.h2000.pipe20_tateji(v) }, // 単管パイプ1.0m（コロバシ：ピン無し）
        pipe15_uchikomi: function (v) { return EasySetTabTab3Data.h2000.pipe20_tateji(v) * 2 }, // 単管パイプ1.5m（打込み：ピン無し）
        orthogonal_clamp: function (v) { return EasySetTabTab3Data.h2000.pipe10_korobashi(v) * 9 / 2 }, // 直交クランプ
        free_clamp: function (v) { return EasySetTabTab3Data.h2000.orthogonal_clamp(v) }, // 自在クランプ
        joint: function (v) { return EasySetTabTab3Data.h2000.pipe40(v) + 2 } // ジョイント
    }
}
