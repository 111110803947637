window.EasySetTabTabFunctions = {
    isFloat: function(number) {
        if (number == null || number == '') {
            return '';
        }
        return Number(number) === number && number % 1 !== 0;
    },
    roundUp: function(number, digits) {
        let factor = Math.pow(10,digits);
        return Math.ceil(number*factor) / factor
    },
    round: function(number, digit) {
        let _sign = (number < 0) ? -1 : 1;
        let _pow = Math.pow(10, digit);
        return Math.round((number * _sign) * _pow) / _pow * _sign;
    },
    roundDown: function(number, digit = 0) {
        let str_number = String(number);
        const nums = str_number.split('.');
        if (nums.length == 1 || digit == 0) {
            return Number(nums[0]);
        } else {
            return Number(`${nums[0]}.${nums[1][0,digit]}`);
        }
    }
}
