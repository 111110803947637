window.EasySetTabTab2Data = {
    '1棟分×250mm幅': [ // 1棟×250mm幅
        {name: '支柱_36支柱A', value: '66'},
        {name: '支柱_27支柱A', value: '2'},
        {name: '支柱_18支柱A', value: ''},
        {name: '支柱_09支柱A', value: '10'},
        {name: '支柱_045支柱A', value: '4'},
        {name: '踏板_4018踏板A', value: ''},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: '72'},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: '12'},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '260'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: '52'},
        {name: '手摺_600手摺', value: '51'},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: ''},
        {name: 'ブラケット_25ブラケット', value: '64'},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '40'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '24'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: '6'},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '40'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: '3'},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: '1'},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '3層3スパン×250mm幅': [ // 3層3スパン×250mm幅
        {name: '支柱_36支柱A', value: '8'},
        {name: '支柱_27支柱A', value: '4'},
        {name: '支柱_18支柱A', value: '4'},
        {name: '支柱_09支柱A', value: ''},
        {name: '支柱_045支柱A', value: ''},
        {name: '踏板_4018踏板A', value: ''},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: '9'},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '12'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: ''},
        {name: '手摺_600手摺', value: ''},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: ''},
        {name: 'ブラケット_25ブラケット', value: '12'},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '8'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '3'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: ''},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '8'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: ''},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: ''},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '3層4スパン×250mm幅': [ // 3層4スパン×250mm幅
        {name: '支柱_36支柱A', value: '8'},
        {name: '支柱_27支柱A', value: '5'},
        {name: '支柱_18支柱A', value: '3'},
        {name: '支柱_09支柱A', value: ''},
        {name: '支柱_045支柱A', value: ''},
        {name: '踏板_4018踏板A', value: ''},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: '12'},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '16'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: ''},
        {name: '手摺_600手摺', value: ''},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: ''},
        {name: 'ブラケット_25ブラケット', value: '15'},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '8'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '5'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: ''},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '8'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: ''},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: ''},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '3層5スパン×250mm幅': [ // 3層5スパン×250mm幅
        {name: '支柱_36支柱A', value: '10'},
        {name: '支柱_27支柱A', value: '6'},
        {name: '支柱_18支柱A', value: '4'},
        {name: '支柱_09支柱A', value: ''},
        {name: '支柱_045支柱A', value: ''},
        {name: '踏板_4018踏板A', value: ''},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: '15'},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '20'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: ''},
        {name: '手摺_600手摺', value: ''},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: ''},
        {name: 'ブラケット_25ブラケット', value: '18'},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '10'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '6'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: ''},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '10'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: ''},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: ''},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '4層10スパン×250mm幅': [ // 4層10スパン×250mm幅
        {name: '支柱_36支柱A', value: '34'},
        {name: '支柱_27支柱A', value: ''},
        {name: '支柱_18支柱A', value: '11'},
        {name: '支柱_09支柱A', value: ''},
        {name: '支柱_045支柱A', value: ''},
        {name: '踏板_4018踏板A', value: ''},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: '40'},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '60'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: ''},
        {name: '手摺_600手摺', value: ''},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: ''},
        {name: 'ブラケット_25ブラケット', value: '44'},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '17'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '12'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: ''},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '17'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: ''},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: ''},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '1棟分×400mm幅': [ // 1棟×400mm幅
        {name: '支柱_36支柱A', value: '66'},
        {name: '支柱_27支柱A', value: '2'},
        {name: '支柱_18支柱A', value: ''},
        {name: '支柱_09支柱A', value: '10'},
        {name: '支柱_045支柱A', value: '4'},
        {name: '踏板_4018踏板A', value: '62'},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: '12'},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: '10'},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '260'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: '52'},
        {name: '手摺_600手摺', value: '51'},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: '64'},
        {name: 'ブラケット_25ブラケット', value: ''},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '40'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '24'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: '6'},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '40'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: '3'},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: '1'},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '3層3スパン×400mm幅': [ // 3層3スパン×400mm幅
        {name: '支柱_36支柱A', value: '8'},
        {name: '支柱_27支柱A', value: '4'},
        {name: '支柱_18支柱A', value: '4'},
        {name: '支柱_09支柱A', value: ''},
        {name: '支柱_045支柱A', value: ''},
        {name: '踏板_4018踏板A', value: '9'},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: ''},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '12'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: ''},
        {name: '手摺_600手摺', value: ''},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: '12'},
        {name: 'ブラケット_25ブラケット', value: ''},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '8'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '3'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: ''},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '8'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: ''},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: ''},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '3層4スパン×400mm幅': [ // 3層4スパン×400mm幅
        {name: '支柱_36支柱A', value: '8'},
        {name: '支柱_27支柱A', value: '5'},
        {name: '支柱_18支柱A', value: '3'},
        {name: '支柱_09支柱A', value: ''},
        {name: '支柱_045支柱A', value: ''},
        {name: '踏板_4018踏板A', value: '12'},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: ''},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '16'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: ''},
        {name: '手摺_600手摺', value: ''},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: '15'},
        {name: 'ブラケット_25ブラケット', value: ''},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '8'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '5'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: ''},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '8'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: ''},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: ''},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '3層5スパン×400mm幅': [ // 3層5スパン×400mm幅
        {name: '支柱_36支柱A', value: '10'},
        {name: '支柱_27支柱A', value: '6'},
        {name: '支柱_18支柱A', value: '4'},
        {name: '支柱_09支柱A', value: ''},
        {name: '支柱_045支柱A', value: ''},
        {name: '踏板_4018踏板A', value: '15'},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: ''},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '20'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: ''},
        {name: '手摺_600手摺', value: ''},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: '18'},
        {name: 'ブラケット_25ブラケット', value: ''},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '10'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '6'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: ''},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '10'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: ''},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: ''},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ],
    '4層10スパン×400mm幅': [ // 4層10スパン×400mm幅
        {name: '支柱_36支柱A', value: '34'},
        {name: '支柱_27支柱A', value: ''},
        {name: '支柱_18支柱A', value: '11'},
        {name: '支柱_09支柱A', value: ''},
        {name: '支柱_045支柱A', value: ''},
        {name: '踏板_4018踏板A', value: '40'},
        {name: '踏板_4012踏板A', value: ''},
        {name: '踏板_4009踏板A', value: ''},
        {name: '踏板_4006踏板A', value: ''},
        {name: '踏板_2518踏板A', value: ''},
        {name: '踏板_2515踏板A', value: ''},
        {name: '踏板_2512踏板A', value: ''},
        {name: '踏板_2509踏板A', value: ''},
        {name: '踏板_2506踏板A', value: ''},
        {name: 'センター踏板_センター踏板 2518', value: ''},
        {name: 'センター踏板_センター踏板 2512', value: ''},
        {name: 'センター踏板_センター踏板 2509', value: ''},
        {name: '手摺_1800手摺', value: '60'},
        {name: '手摺_1500手摺', value: ''},
        {name: '手摺_1200手摺', value: ''},
        {name: '手摺_900手摺', value: ''},
        {name: '手摺_600手摺', value: ''},
        {name: '手摺_400手摺', value: ''},
        {name: '手摺_300手摺', value: ''},
        {name: '手摺_200手摺', value: ''},
        {name: 'ブラケット_40ブラケット', value: '44'},
        {name: 'ブラケット_25ブラケット', value: ''},
        {name: 'ブラケット_40張出ブラケット', value: ''},
        {name: 'ブラケット_25張出ブラケット', value: ''},
        {name: 'ブラケット_20張出ブラケット', value: ''},
        {name: 'ジャッキ_固定ジャッキ', value: '17'},
        {name: '梯子_梯子36A', value: ''},
        {name: '梯子_梯子27A', value: ''},
        {name: '梯子_梯子18A', value: ''},
        {name: '筋交_筋交18A', value: '12'},
        {name: '筋交_筋交15A', value: ''},
        {name: '筋交_筋交12A', value: ''},
        {name: '筋交_筋交09A', value: ''},
        {name: '梁枠_3スパントラス', value: ''},
        {name: '梁枠_2スパントラス', value: ''},
        {name: 'センターアダプター_センターアダプター(1型)', value: ''},
        {name: 'センターアダプター_センターアダプター(2型)', value: ''},
        {name: 'アンダーベース_アンダーベース（プラ）', value: '17'},
        {name: 'アンダーベース_アンダーベース（スチール）', value: ''},
        {name: '階段・扉_昇降階段（アルミ製）', value: ''},
        {name: '階段・扉_昇降階段（鋼製）', value: ''},
        {name: '階段・扉_階段ハーフ', value: ''},
        {name: '階段・扉_補助ステップ', value: ''},
        {name: 'その他_サイドアダプター', value: ''},
        {name: 'アンダーベース_下屋用アンダーベース', value: ''},
        {name: 'ロックピン_ロックピン(先付け)', value: ''}
    ]
}
