window.EasySetTabTab4Data = {
    frame600: {
        yamato_pin: function({ step = '', side = '' }){ return step * (side + 1) },
        brace18: function({ step = '', side = '' }){ return (step * (side + 1)) * 2 },
        ledger_strip18: function({ step = '', side = '' }){ return (step * (side + 1)) * 2 },
        anti18: function({ step = '', side = '' }){ return (step - 1) * side - (step - 1) },
        // half18: function({ step = '', side = '' }){ return (step - 1) * side },
        aluminum_stairs: function({ step = '', side = '' }){ return step - 1 },
        handrail: function({ step = '', side = '' }){ return (step - 1) * 2 },
        stair_guard: function({ step = '', side = '' }){ return step - 1 },
        jack_baseL380: function({ step = '', side = '' }){ return (side + 1) * 2 },
        angle: function({ step = '', side = '' }){ return (side + 1) * 2 },
        wall_tie24_34: function({ step = '', side = '', total_span = '' }){ return Math.round(total_span / 4) },
        stopper: function({ step = '', side = '' }){ return step * 4 },
        meshsheet18_51: function({ step = '', side = '', splitV_H = '' }){ return Math.round(splitV_H / 3 * splitV_H) },
        soundproofpanel18: function({ step = '', side = '', splitV_H = '' }){ return splitV_H * 2 * splitV_H }
    },
    frame900: {
        yamato_pin: function({ step = '', side = '' }){ return step * (side + 1) },
        brace18: function({ step = '', side = '' }){ return (step * (side + 1)) * 2 },
        ledger_strip18: function({ step = '', side = '' }){ return (step * (side + 1)) * 2 },
        anti18: function({ step = '', side = '' }){ return (step - 1) * side - (step - 1) },
        half18: function({ step = '', side = '' }){ return (step - 1) * side },
        aluminum_stairs: function({ step = '', side = '' }){ return step - 1 },
        handrail: function({ step = '', side = '' }){ return (step - 1) * 2 },
        stair_guard: function({ step = '', side = '' }){ return step - 1 },
        jack_baseL380: function({ step = '', side = '' }){ return (side + 1) * 2 },
        angle: function({ step = '', side = '' }){ return (side + 1) * 2 },
        wall_tie24_34: function({ step = '', side = '', total_span = '' }){ return Math.round(total_span / 4) },
        stopper: function({ step = '', side = '' }){ return step * 4 },
        meshsheet18_51: function({ step = '', side = '', splitV_H }){ return Math.round(splitV_H / 3 * splitV_H) },
        soundproofpanel18: function({ step = '', side = '', splitV_H }){ return splitV_H * 2 * splitV_H }
    }
}
