window.BlockUserToogleFollowBtn = function() {
    if ($('.blocked_follow_btn').length) {
        $('.follow-wrapper').hide();
    } else {
        $('.follow-wrapper').show();
    }
}

$(function(){
    BlockUserToogleFollowBtn();

    const confirmBlockModal = (document.getElementById('confirm-block')) ? new bootstrap.Modal(document.getElementById('confirm-block'), {}) : null;
    const confirmUnBlockModal = (document.getElementById('confirm-unblock')) ? new bootstrap.Modal(document.getElementById('confirm-unblock'), {}) : null;

    $('#accept-block').on('click', function(){
        let blocked_id = $(this).data('user-id');
        let create_or_destroy = $(this).data('create-or-destroy');
        let url = $(this).data('url');
        let data = {
            blocked_id: blocked_id,
            create_or_destroy: create_or_destroy
        }
        $.ajax({
            url: url,
            type: 'POST',
            cache: false,
            dataType: 'script',
            data: data
        }).done(function() {
        }).fail(function() {
        }).always(function() {
            confirmBlockModal.hide();
            location.reload();
        });
    })

    $('#close-confirm-block').on('click', function(){
        confirmBlockModal.hide();
    })

    $('.block_btn').on('click', function() {
        $('#accept-block').data('user-id', $(this).data('user-id'));
        confirmBlockModal.show();
    });

    $('#accept-unblock').on('click', function(){
        let blocked_id = $(this).data('user-id');
        let create_or_destroy = $(this).data('create-or-destroy');
        let url = $(this).data('url');
        let data = {
            blocked_id: blocked_id,
            create_or_destroy: create_or_destroy
        }
        $.ajax({
            url: url,
            type: 'POST',
            cache: false,
            dataType: 'script',
            data: data
        }).done(function(html) {
        }).fail(function() {
            popupErrorOnebutton.openWith('エラーが起きました');
        }).always(function() {
            confirmUnBlockModal.hide();
            location.reload();
        });
    })

    $('#close-confirm-unblock').on('click', function(){
        confirmUnBlockModal.hide();
    })

    $('.unblock_btn').on('click', function() {
        $('#accept-unblock').data('user-id', $(this).data('user-id'));
        confirmUnBlockModal.show();
    });
});
