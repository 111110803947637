window.EasySetTabTab5Data = {
    w600: {
        // 支柱
        '3800支柱': function({height = '', total_span = '', step = '',  span = ''}){ return  ((Math.floor((height - 2.75) / 3.8)) * (span + 1)) * 2 },
        '1900支柱': function({total_span = '', step = '',  span = ''}){ return (span + 1) * 4 },
        '0950支柱': function({total_span = '', step = '',  span = ''}){ return (span + 1) * 4 },
        // 下部支柱
        '2750下部支柱': function({total_span = '', step = '',  span = ''}){ return (span + 1) * 2 },
        // ジャッキベース
        'ジャッキベース': function({total_span = '', step = '',  span = ''}){ return (span + 1) * 2 },
        // 先行手摺
        '1829先行手摺': function({total_span = '', step = '',  span = ''}){ return total_span },
        '1524先行手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) },
        '1219先行手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.15) },
        '0914先行手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.11) },
        '0610先行手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) },
        // 手摺
        '1829手摺': function({total_span = '', step = '',  span = ''}){ return total_span * 2 },
        '1524手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) * 2 },
        '1219手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.15) * 2 },
        '0914手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.11) * 2 },
        '0610手摺': function({total_span = '', step = '',  span = ''}){ return (step * 4) + (step - 1) * (span + 1) + (Math.round(total_span * 0.07)) * 2 },
        // 階段
        'IQマルチタラップ': function({total_span = '', step = '',  span = ''}){ return step - 1 },
        // 階段手すり
        '階段手摺': function({total_span = '', step = '',  span = ''}){ return (step - 1) * 2 },
        // 階段開口部手摺
        '階段開口部手摺': function({total_span = '', step = '',  span = ''}){ return step - 1 },
        // Gウォーク
        '4918Gウォーク': function({total_span = '', step = '',  span = ''}){ return (step - 1) * span - (step - 1)},
        '4915Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) },
        '4912Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.15) },
        '4909Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.11) },
        '4906Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(Math.round(total_span * 0.07) / 2)}
    },
    w900: {
        // 支柱
        '3800支柱': function({height = '', total_span = '', step = '',  span = ''}){ return  ((Math.floor((height - 2.75) / 3.8)) * (span + 1)) * 2 },
        '1900支柱': function({total_span = '', step = '',  span = ''}){ return (span + 1) * 4 },
        '0950支柱': function({total_span = '', step = '',  span = ''}){ return (span + 1) * 4 },
        // 下部支柱
        '2750下部支柱': function({total_span = '', step = '',  span = ''}){ return (span + 1) * 2 },
        // ジャッキベース
        'ジャッキベース': function({total_span = '', step = '',  span = ''}){ return (span + 1) * 2 },
        // 先行手すり
        '1829先行手摺': function({total_span = '', step = '',  span = ''}){ return total_span },
        '1524先行手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) },
        '1219先行手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.15) },
        '0914先行手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.11) },
        '0610先行手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) },
        // 手すり
        '1829手摺': function({total_span = '', step = '',  span = ''}){ return total_span * 2 },
        '1524手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) * 2 },
        '1219手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.15) * 2 },
        '0914手摺': function({total_span = '', step = '',  span = ''}){ return (step * 4) + (step - 1) * (span + 1) + (Math.round(total_span * 0.11)) * 2 },
        '0610手摺': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) * 2 },
        // 階段
        'IQマルチタラップ': function({total_span = '', step = '',  span = ''}){ return step - 1 },
        // 階段手すり
        '階段手摺': function({total_span = '', step = '',  span = ''}){ return (step - 1) * 2 },
        // 階段開口部手摺
        '階段開口部手摺': function({total_span = '', step = '',  span = ''}){ return step - 1 },
        // Gウォーク
        '4918Gウォーク': function({total_span = '', step = '',  span = ''}){ return (step - 1) * span - (step - 1)},
        '4915Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) },
        '4912Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.15) },
        '4909Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.11) },
        '4906Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) },
        '2418Gウォーク': function({total_span = '', step = '',  span = ''}){ return (step - 1) * span },
        '2415Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) },
        '2412Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.15) },
        '2409Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.11) },
        '2406Gウォーク': function({total_span = '', step = '',  span = ''}){ return Math.round(total_span * 0.07) }
    }
}
