// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require swiper/swiper-bundle.js
//= require swiper.js
require("jquery")

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()

// Formatter for form:input
import '../src/jpostal';

// Drawer
import '../src/swiper';
import '../src/jquery.switcher.min';

// for loading spinner problem
window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload();
    }
};

jQuery(function($){
    $(document).ajaxSend(function() { $("#spinner-overlay").fadeIn(300); });
    $(document).ajaxComplete(function() { $("#spinner-overlay").fadeOut(300); });

    $('a[target != "_blank"]').click(function(e){
        let url = $(this).attr('href');
        if (url && url != '#' && url != 'javascript:void(0);') {
            e.preventDefault();
            window.location.href = url;
        }
    });
});

document.addEventListener('DOMContentLoaded',
    function(){
        $("#spinner-overlay").fadeOut(0);
        setTimeout(function(){$("#spinner-overlay").delay(100).fadeOut(300);}, 2000);
    }
);

import '../src/direct_upload';

import '../src/block';
import '../src/scroll';
import '../src/datepicker_custom';
import '../src/datepicker_custom.ja';

require('../src/easy_set_search_data');
require('../src/easy_set_tab1_data');
require('../src/easy_set_tab2_data');
require('../src/easy_set_tab3_data');
require('../src/easy_set_tab4_data');
require('../src/easy_set_tab5_data');
require('../src/easy_set_search');

require('../src/confirm_modal');
