class ConfirmModal {
    static showModal = (message, trueLabel, falseLabel, trueFunction, falseFunction) => {
        const modalHtml =
            '<div class="modal" tabindex="-1" id="confirm-modal" style="z-index: 100000;">\n' +
            '  <div class="modal-dialog modal-dialog-centered">\n' +
            '    <div class="modal-content" style="border-radius: 16px;">\n' +
            '      <div class="text-center">\n' +
            '        <div style="padding: 1.1rem;">\n' +
            '          <div id="confirm-modal-message" style="line-height: 3em;font-size: .9rem; font-weight: bold;color: #575C67;">\n' +
            message +
            '          </div>\n' +
            '        </div>\n' +
            '        <table style="width:100%;border-collapse: collapse;border-top:1px solid #000;line-height: 4em;">\n' +
            '          <tr>\n' +
            '            <td id="confirm-modal-true" class="text-center" style="width:50%;border-right: 1px solid #000;color: #347CB9;">\n' +
            `              ${trueLabel}\n` +
            '            </td>\n' +
            '            <td id="confirm-modal-false" class="text-center" style="color: #347CB9;">\n' +
            `              ${falseLabel}\n` +
            '            </td>\n' +
            '          </tr>\n' +
            '        </table>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>'

        document.getElementById("modalBlock").innerHTML = modalHtml;
        const confirmModal = new bootstrap.Modal(document.getElementById('confirm-modal'), {})

        document.getElementById("confirm-modal-true").onclick = () => {
            trueFunction();
            confirmModal.hide();
        };

        document.getElementById("confirm-modal-false").onclick = () => {
            falseFunction();
            confirmModal.hide();
        };

        confirmModal.show();

    }
}

window.ConfirmModal = ConfirmModal;
