class EasySetTab {
    static changeMaker() {
        let maker_ids = $('[name="easy_set_search_form[maker_ids][]"]:checked').map(function(){
            console.log($(this).val());
            return $(this).val();
        }).get();
        console.log(maker_ids);
        if (maker_ids == 0) maker_ids = null;
        let url = $('#maker_ids').data('url');

        $.ajax({
            url: url,
            type: 'GET',
            data: {
                maker_ids: maker_ids
            },
            cache: false,
            dataType: 'json'
        }).done(function(json, httpstatus, xhr) {
            json.forEach(function (row) {
                if (row[1] != null) {
                    let id = row[0];
                    let disabled = row[1];
                    if (disabled == true) {
                        $(`[data-material-id=${id}]`).hide();
                        $(`[data-material-id=${id}]`).find('input[type=number]').val('');
                    } else {
                        $(`[data-material-id=${id}]`).show();
                    }
                }
            });
        }).fail((jqXHR, textStatus, errorThrown) => {
            popupErrorOnebutton.openWith("エラーが発生しました");
        });
    }

    static setValue({name = '', value = '', not_autofill}) {
        if (EasySetTabTabFunctions.isFloat(value)) {
            value = EasySetTabTabFunctions.roundUp(value, 0);
        }
    //    console.log(`${name} = ${value}`)
        if ($(`input[data-material-name="${name}"]`).length == 0) {
            console.log("■■■■■■■■ ng : =" + name + "=");
            not_autofill.push(name)
            return not_autofill
        }
        $(`input[data-material-name="${name}"]`).val(value);
        return not_autofill
    }

    static getValue({name = ''}) {
        $(`input[data-material-name="${name}"]`).val();
    }

    static success() {
        this.updateWeight();
        $('#popup-error-onebutton-msg').html('反映しました');
        popupErrorOnebutton.show();
    }

    static updateWeight() {
        let total_weight = $('[data-material-amount=true]').map(function(){
            if ($(this).val() != "")  {
                return Number($(this).val()) * Number($(this).data('material-weight'));
            } else {
                return 0;
            }
        }).get().reduce((sum, element) => sum + element, 0);
        $('#total-weight').text(parseInt(total_weight).toLocaleString());
        // $(document).on('change', '[data-material-amount=true]', function (){
        // });
    }

    static error(message) {
        $('#popup-error-onebutton-msg').html(message);
        popupErrorOnebutton.show();
    }

    static easySetTabAutoSet(btn) {
        const form = $(btn).closest('.tab-pane');
        const id = form.attr('id').replace('-', '');
        switch (id) {
            case 'tab1':
                this.tab1({
                    area: form.find("[name='area']").val(),
                    height: form.find("[name='height']").val(),
                    width: form.find("[name='width']").val()
                });
                break;
            case 'tab2':
                this.tab2({
                    set: form.find("[name='set']").val(),
                    width: form.find("[name='width']").val()
                });
                break;
            case 'tab3':
                let ng_cases = this.tab3({
                    length: form.find("[name='length']").val(),
                    thickness: form.find("[name='thickness']").val().replace('厚さ', ''),
                    height: form.find("[name='height']").val()
                });
                return ng_cases;
            case 'tab4':
                this.tab4({
                    area: form.find("[name='area']").val(),
                    height: form.find("[name='height']").val(),
                    frame: form.find("[name='frame']").val()
                });
                break;
            case 'tab5':
                this.tab5({
                    area: form.find("[name='area']").val(),
                    height: form.find("[name='height']").val(),
                    width: form.find("[name='width']").val()
                })
                break;
        }
    }

    static tab1({area = '', height = '', width = ''}) {
        if (area == '' || height == '' || width == '') {
            this.error('平米数・おおよその高さを入力してください');
            return true;
        }
        $('[data-material-name]').val('');

        let calcs = new window.EasySetTabTab1Data({ area: area, height: height, width: width });
        for (let name in calcs.logics) {
            EasySetTab.setValue({name: name, value: calcs.logics[name] });
        }

        this.success();
    }

    static tab2({set = '', width = ''}) {
        if (set == '' || width == '') {
            this.error('セット内容・踏板幅を選択してください');
            return true;
        }
        $('[data-material-name]').val('');

        const dataSetName = `${set}×${width}幅`;
        $(window.EasySetTabTab2Data[dataSetName]).each(function(_, h){
            EasySetTab.setValue({name: h['name'], value: h['value']});
        });

        this.success();
    }

    static tab3({length = '', thickness = '', height = ''}) {
        if (length == '' || thickness == '' || height == '') {
            this.error('施工距離を入力してください');
            return true;
        }
        $('[data-material-name]').val('');
        let ng_cases = []

        if (height.substring(0, 2) == '3M') {
            this.setValue({
                name: `安全鋼板_3.0m ${thickness}`,
                value: EasySetTabTab3Data.h3000.board(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: 'フックボルト_フックボルト',
                value: EasySetTabTab3Data.h3000.hookbolt(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_4.0m（布地：ピン無し）',
                value: EasySetTabTab3Data.h3000.pipe40(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_3.0m（建地：ピン無し）',
                value: EasySetTabTab3Data.h3000.pipe30(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_2.5m（控え：ピン無し）',
                value: EasySetTabTab3Data.h3000.pipe25(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_2.0m',
                value: EasySetTabTab3Data.h3000.pipe20(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_1.5m（コロバシ：ピン無し）',
                value: EasySetTabTab3Data.h3000.pipe15_korobashi(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_1.5m（打込み：ピン無し）',
                value: EasySetTabTab3Data.h3000.pipe15_uchikomi(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '直交クランプ_直交クランプ',
                value: EasySetTabTab3Data.h3000.orthogonal_clamp(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '自在クランプ_自在クランプ',
                value: EasySetTabTab3Data.h3000.free_clamp(length),
                not_autofill: ng_cases
            });
            // ジョイントが今ない
            this.setValue({
                name: 'ジョイント_ジョイント',
                value: EasySetTabTab3Data.h3000.joint(length),
                not_autofill: ng_cases
            });
        } else {
            this.setValue({
                name: `安全鋼板_2.0m ${thickness}`,
                value: EasySetTabTab3Data.h2000.board(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: 'フックボルト_フックボルト',
                value: EasySetTabTab3Data.h2000.hookbolt(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_4.0m（布地：ピン無し）',
                value: EasySetTabTab3Data.h2000.pipe40(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_2.0m（建地：ピン無し）',
                value: EasySetTabTab3Data.h2000.pipe20_tateji(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_2.0m（控え：ピン無し）',
                value: EasySetTabTab3Data.h2000.pipe20_hikae(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_1.0m（コロバシ：ピン無し）',
                value: EasySetTabTab3Data.h2000.pipe10_korobashi(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '単管パイプ_1.5m（打込み：ピン無し）',
                value: EasySetTabTab3Data.h2000.pipe15_uchikomi(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '直交クランプ_直交クランプ',
                value: EasySetTabTab3Data.h2000.orthogonal_clamp(length),
                not_autofill: ng_cases
            });
            this.setValue({
                name: '自在クランプ_自在クランプ',
                value: EasySetTabTab3Data.h2000.free_clamp(length),
                not_autofill: ng_cases
            });
            // ジョイントが今ない
            this.setValue({
                name: 'ジョイント_ジョイント',
                value: EasySetTabTab3Data.h2000.joint(length),
                not_autofill: ng_cases
            });
        }

        this.success();
        return ng_cases
    }

    static tab4({area = '', height = '', frame = ''}) {
        if (area == '' || height == '' || frame == '') {
            console.log(area)
            console.log(height)
            console.log(frame)
            this.error('平米数・施工建物のおおよその高さを入力してください');
            return true;
        }
        $('[data-material-name]').val('');

        let total_span = Math.round(area/3.155);
        let splitV_H = Math.round(Math.sqrt(total_span));
        let step = Math.round(height/1.725);
        let side = Math.round(total_span/step);
        let vertical = Math.round(Math.sqrt(total_span * 1 / 3));
        let side2 = Math.round(Math.sqrt(total_span * 3 / 1));

        let values = {
            total_span: total_span,
            splitV_H: splitV_H,
            step: step,
            side: side,
            vertical: vertical,
            side2: side2
        };

        let data = null;
        if (frame == '600枠') {
            data = EasySetTabTab4Data.frame600

            this.setValue({name: '建枠_600枠',  value: data.yamato_pin(values)});
            this.setValue({name: '筋交_1829',  value: data.brace18(values)});
            this.setValue({name: '下桟_1829',  value: data.ledger_strip18(values)});
            this.setValue({name: '鋼製布板 500_500×1829',  value: data.anti18(values)});
            // this.setValue({name: '鋼製布板240×1829',  value: data.anti18(values)});
            this.setValue({name: 'アルミ階段_アルミ階段',  value: data.aluminum_stairs(values)});
            this.setValue({name: '階段手摺_IQKDT-19',  value: data.handrail(values)});
            this.setValue({name: 'セイフティーガード_セイフティーガード',  value: data.stair_guard(values)});
            this.setValue({name: 'ジャッキベース_ジャッキベース',  value: data.jack_baseL380(values)});
            this.setValue({name: '敷角_敷角',  value: data.angle(values)});
            this.setValue({name: '壁つなぎ_2434',  value: data.wall_tie24_34(values)});
            this.setValue({name: 'エンドストッパー_600~900',  value: data.stopper(values)});
            this.setValue({name: 'メッシュシート_1.8×5.1',  value: data.meshsheet18_51(values)});
            this.setValue({name: '防音パネル_18', value: data.soundproofpanel18(values)});
        } else if (frame == '900枠') {
            data = EasySetTabTab4Data.frame900

            this.setValue({name: '建枠_900枠',  value: data.yamato_pin(values)});
            this.setValue({name: '筋交_1829',  value: data.brace18(values)});
            this.setValue({name: '下桟_1829',  value: data.ledger_strip18(values)});
            this.setValue({name: '鋼製布板 500_500×1829',  value: data.anti18(values)});
            // this.setValue({name: '鋼製布板240×1829',  value: data.anti18(values)});
            this.setValue({name: 'アルミ階段_アルミ階段',  value: data.aluminum_stairs(values)});
            this.setValue({name: '階段手摺_IQKDT-19',  value: data.handrail(values)});
            this.setValue({name: 'セイフティーガード_セイフティーガード',  value: data.stair_guard(values)});
            this.setValue({name: 'ジャッキベース_ジャッキベース',  value: data.jack_baseL380(values)});
            this.setValue({name: '敷角_敷角',  value: data.angle(values)});
            this.setValue({name: '壁つなぎ_2434',  value: data.wall_tie24_34(values)});
            this.setValue({name: 'エンドストッパー_600~900',  value: data.stopper(values)});
            this.setValue({name: 'メッシュシート_1.8×5.1',  value: data.meshsheet18_51(values)});
            this.setValue({name: '防音パネル_18', value: data.soundproofpanel18(values)});
        }

        this.success();
    }

    static tab5({area = '', height = '', width = ''}) {
        if (area == '' || height == '' || width == '') {
            this.error('平米数・施工建物のおおよその高さを入力してください');
            return true;
        }
        $('[data-material-name]').val('');

        let total_span = Math.round(area/3.4);  // 総スパン数
        let step = Math.round(height/1.9);      // 段数
        let span = Math.round(total_span/step); // スパン数

        let values = {
            height: height,
            total_span: total_span,
            step: step,
            span: span
        };
        function nameCap(name) {
            return name.replace('-', '');
        }
        const names = $('[data-material-name]').map(function() { return $(this).attr('data-material-name')})
        $(names).each(function(_, name) {
            let form_name = name;
            let capName = nameCap(name.split('_')[1]);
            let data = null;
            if (width == '600') {
                data = EasySetTabTab5Data.w600;
            } else if (width == '900') {
                data = EasySetTabTab5Data.w900;
            }

            if (data[capName]) {
                EasySetTab.setValue({
                    name: form_name,
                    value: data[capName](values)
                });
            } else if (data[capName+'H']) {
                EasySetTab.setValue({
                    name: form_name,
                    value: data[capName+'H'](values)
                });
            } else {
                EasySetTab.setValue({
                    name: form_name,
                    value: ''
                });
            }
        });

        this.success();
    }
}

window.EasySetTab = EasySetTab
